import { Editor } from 'primereact/editor';
import React from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";


const TextEditor = ({ text, setText }) => {


    return (
        <div className="card">
            <label className="label">
                <span className="label-text">News Details: </span>
            </label>
            <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: '350px' }} className='custom-css' />
        </div>
    );
};

export default TextEditor;