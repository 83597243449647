/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ImCross } from 'react-icons/im';
import { MdRateReview } from 'react-icons/md';
import { Dna } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { userContext } from '../../../Context/UserContext/UserProvider/UserProvider';
import TextEditor from '../AddArticle/TextEditor';
import TitleTextEditor from '../AddArticle/TitleTextEditor';

const UpdateArticle = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagId, setSelectedTagId] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [tags, setTags] = useState([]);
  const [articleTags, setArticleTags] = useState([]);
  const [articleCategories, setArticleCategories] = useState([]);
  const [imageStatus, setImageStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [article, setArticle] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [articleStatus, setArticleStatus] = useState('');
  const [articleSubtitle, setArticleSubtitle] = useState('');
  const { user } = useContext(userContext);
  const [text, setText] = useState('');
  const [subTitleText, setSubTitleText] = useState('');
  const [titleText, setTitleText] = useState('');

  const isAdmin = user?.role === 'admin';


  const id = useParams();

  // get Update Article-----------------------------
  useEffect(() => {
    axios(`https://api.nagoriktv.com/articlewithoutfilter/${id?.id}`)
      .then((res) => {
        setArticle(res?.data);
        setText(res?.data?.paragraph)
        setTitleText(res?.data?.title2)
        setSubTitleText(res?.data?.subtitle2)
        setArticleStatus(res?.data?.status);
        setArticleSubtitle(res?.data?.subtitle);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [id, refresh]);

  let images = [];
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const UpdateArticle = {
      title: data?.title ? data?.title : article?.title,
      subtitle: data?.subtitle ? data?.subtitle : articleSubtitle,
      title2: titleText ? titleText : article?.title2,
      subtitle2: subTitleText ? subTitleText : article?.subtitle2,
      author: data?.author ? data?.author : article?.author,
      content: data?.content ? data?.content : article?.content,
      caption: data?.caption ? data?.caption : article?.caption,
      user_id: 1,
      paragraph: text ? text : data?.paragraph,
      publication_date: data?.date ? data?.date : article?.publication_date,
      status: articleStatus ? articleStatus : 'draft',
      categories: categoryId
        ? [{ category_id: categoryId && categoryId }]
        : articleCategories,
      images: allImages?.length ? allImages : article?.images,
      tags: selectedTagId?.length ? selectedTagId : articleTags,
      metadata: [],
      sub_categories: [
        { sub_category_id: parseInt(data?.subcategory && data?.subcategory) },
      ],
    };

    setImageStatus('');

    const url = `https://api.nagoriktv.com/articleapi/${article?.article_id}`;
    axios
      .put(url, UpdateArticle)
      .then((res) => {
        if (res.data) {
          Swal.fire('Good job!', 'Article is Updated Successfully!', 'success');
          reset();
          setImageStatus('');
          setSelectedTagId([]);
          setSelectedTags([]);
          setRefresh(!refresh);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  let getArticleTags = [];
  // Get all tags of the Articles-------------------------
  useEffect(() => {
    axios('https://api.nagoriktv.com/tags/')
      .then((res) => {
        const matchTags = res?.data?.filter((tag) =>
          article?.tags?.find((aTag) => aTag === tag?.name)
        );

        for (const tag of matchTags) {
          const tags = {
            tag_id: tag?.tag_id,
          };
          getArticleTags.push(tags);
        }
        setTags(res.data);
        setArticleTags(getArticleTags);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [article]);

  let getArticleCategories = [];
  // get all categories of an Article-------------------------
  useEffect(() => {
    axios('https://api.nagoriktv.com/categories/')
      .then((res) => {
        const matchCategories = res?.data?.filter((category) =>
          article?.categories?.find((aCategory) => aCategory === category?.name)
        );

        for (const category of matchCategories) {
          const categories = {
            category_id: category?.category_id,
          };
          getArticleCategories.push(categories);
        }
        setArticleCategories(getArticleCategories);
        setCategories(res?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [article]);

  //   Handle Tags-----------------------------------
  const handleTags = (e) => {
    const selectedTagValue = e.target.value;
    const tags = {
      tag_id: selectedTagValue,
    };
    setSelectedTagId([...selectedTagId, tags]);

    const selectedTagName = e.target.options[e.target.selectedIndex].text;
    if (!selectedTags.includes(selectedTagName)) {
      setSelectedTags([...selectedTags, selectedTagName]);
    }
  };

  //   Image upload here--------------------------------------
  const handleImage = (e) => {
    const getImages = e.target.files;
    setIsLoading(true);
    setImageStatus('');
    const formData = new FormData();

    for (let i = 0; i < getImages?.length; i++) {
      formData.append('file', getImages[i]);

      const url = 'https://api.nagoriktv.com/images/upload';
      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          images.push({ file_name: getImages[i].name, file_path: data[0] });
          if (images.length === getImages.length) {
            setImageStatus('Successfully Upload !');
            setIsLoading(false);
            setAllImages(images);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          // console.error('Error:', error);
        });
    }
  };

  // handle category--------------------------------
  const handleCategory = (e) => {
    const category = e.target.value;

    const categoryId = category.split(' ')[0];
    setCategoryId(categoryId);

    const selectedSubCategories = allSubCategories?.filter(
      (category) => category?.category_id === parseInt(categoryId)
    );
    setSelectedSubCategories(selectedSubCategories);
  };

  // get all subcategories -------------------------
  useEffect(() => {
    axios('https://api.nagoriktv.com/sub-category/').then((res) => {
      setAllSubCategories(res?.data);
    });
  }, []);

  return (
    <div>
      <div className="px-5 py-3 shadow-md bg-white">
        <h2 className="text-2xl font-bold flex gap-2 items-center">
          <MdRateReview className="text-3xl" />
          <span>Update Article</span>
        </h2>
      </div>

      {/* Update Article form -------------------- */}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-5 rounded-md shadow-lg m-10 bg-white flex gap-5 flex-col justify-center"
      >
        <div>
          <h2 className="text-2xl font-semibold">Update Article</h2>
          <hr className="mt-2" />
        </div>
        <div>
          <label className="label">
            <span className="label-text">Update Article Title : </span>
          </label>
          <input
            type="text"
            defaultValue={article?.title}
            className="px-3 py-3 border w-full rounded-md"
            {...register('title', { required: false })}
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text">Update Article Title2 : </span>
          </label>
          <TitleTextEditor text={titleText} setText={setTitleText} />
        </div>

        <div>
          <label className="label">
            <span className="label-text">Update Subtitle : </span>
          </label>
          <input
            type="text"
            defaultValue={articleSubtitle}
            className="px-3 py-3 border w-full rounded-md"
            {...register('subtitle', { required: false })}
            onChange={(e) => setArticleSubtitle(e.target.value)}
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text">Update Article Subtitle2 : </span>
          </label>
          <TitleTextEditor text={subTitleText} setText={setSubTitleText} />
        </div>
        <div>
          <label className="label">
            <span className="label-text">Update Content : </span>
          </label>
          <textarea
            type="text"
            defaultValue={article?.content}
            className="px-3 h-28 py-3 border w-full rounded-md"
            {...register('content', {
              required: false,
            })}
          />
          {/* {errors.content && <span className='text-error'>{errors.content.message}</span>} */}
        </div>

        <div className="grid grid-cols-3 gap-5">
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Update Category : </span>
            </label>
            <select
              onChange={handleCategory}
              className="px-3 py-3 border w-full rounded-md"
            >
              <option value="">{article?.categories}</option>
              {categories?.map((category) => (
                <option
                  key={category?.category_id}
                  value={category?.category_id + ' ' + category?.name}
                >
                  {category?.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Update Sub Category : </span>
            </label>
            <select
              className="px-3 py-3 border w-full rounded-md"
              {...register('subcategory', { required: false })}
            >
              <option value="">{article?.sub_categories}</option>
              {selectedSubCategories?.map((category) => (
                <option key={category?.id} value={category?.id}>
                  {category?.sub_category_name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="label">
              <span className="label-text">Update Publication Date : </span>
            </label>
            <input
              type="date"
              defaultValue={article?.publication_date}
              className="px-3 py-3 border w-full rounded-md"
              {...register('date', { required: false })}
            />
          </div>
        </div>

        {
          isAdmin &&
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Update Status: </span>
            </label>
            <select
              className="px-3 py-3 border w-full rounded-md"
              {...register('status', { required: false })}
              onChange={(e) => setArticleStatus(e.target.value)}
              value={articleStatus}
            // defaultValue={articleStatus}
            >
              <option value="draft">Draft</option>
              {/* Add more status options here */}
              <option value="published">Published</option>
              <option value="review">Review</option>
            </select>
          </div>
        }

        <div className="form-control w-full">
          <label className="label">
            <div className="flex gap-4 items-center">
              <span className="label-text">Update Tags : </span>
              <div className="flex gap-1">
                {selectedTags?.length ? (
                  <div className="flex gap-1">
                    {selectedTags.map((tag, index) => (
                      <span
                        key={index}
                        className="flex items-center bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700"
                      >
                        #{tag}
                        <ImCross
                          className="ml-2 outline-none focus:outline-none text-red-600"
                          onClick={() => {
                            setSelectedTags((prevTags) =>
                              prevTags.filter((_, i) => i !== index)
                            );
                          }}
                        />
                      </span>
                    ))}
                  </div>
                ) : (
                  <div className="flex gap-1">
                    {article?.tags?.map((tag, index) => (
                      <span
                        key={index}
                        className="flex items-center bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700"
                      >
                        #{tag}
                        <ImCross
                          className="ml-2 outline-none focus:outline-none text-red-600"
                          onClick={() => {
                            setSelectedTags((prevTags) =>
                              prevTags.filter((_, i) => i !== index)
                            );
                          }}
                        />
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </label>
          <select
            className="px-3 py-3 border w-full rounded-md"
            {...register('tag', { required: false })}
            onChange={(e) => handleTags(e)}
          >
            <option value="">Select Tag</option>
            {tags?.map((tag) => (
              <option key={tag?.tag_id} value={tag?.tag_id}>
                {tag?.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="label">
            <span className="label-text">Author Name : </span>
          </label>
          <input
            type="text"
            placeholder="Enter Author Name"
            defaultValue={article?.author}
            {...register('author', { required: false })}
            className="px-3 py-3 border w-full rounded-md"
          />

          {/* {errors.name && <span className='text-error'>{errors.name.message}</span>} */}
        </div>
        <TextEditor text={text} setText={setText} />
        <div className="p-5 bg-[#F8F8FF] mx-20 my-10">
          <span className="label-text font-semibold text-xl">
            Upload Update Image:{' '}
          </span>
          <label
            htmlFor="upload-photo"
            className="label flex flex-col justify-center items-center cursor-pointer bg-green-100 border border-dashed border-black rounded-lg p-14 mt-2"
          >
            <div className="flex gap-5 justify-between w-full">
              <div>
                {article?.images && (
                  <img
                    src={article?.images[0]?.file_path}
                    alt=""
                    className="rounded-md"
                  />
                )}
              </div>
              <div className="w-full flex flex-col items-center justify-center">
                <p className="font-semibold">
                  Drop your File or <span className="text-primary">Browse</span>
                </p>
                <hr className="border-b border-black border-dashed w-full my-2" />

                <p className="text-xl font-bold text-success mt-5">
                  {imageStatus}
                </p>

                {isLoading && (
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                  />
                )}
              </div>
            </div>
          </label>
          <input
            onChange={handleImage}
            multiple
            type="file"
            id="upload-photo"
            className="px-3 py-3 border w-full rounded-md hidden"
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text">Image Caption : </span>
          </label>
          <input
            type="text"
            placeholder="Enter Image Caption"
            defaultValue={article?.caption}
            {...register('caption', { required: false })}
            className="px-3 py-3 border w-full rounded-md"
          />
        </div>

        <div className="w-full flex justify-center">
          <input
            type="submit"
            value="Update"
            className="bg-[#158488] hover:bg-[#19b1b6] duration-500 cursor-pointer py-3 w-full rounded-md text-white"
          />
        </div>
      </form>
    </div>
  );
};

export default UpdateArticle;
